import axios from "axios";

export default function api() {
    const instance = axios.create({
        // baseURL: 'https://calc2.synchrorussia.ru/'
        //baseURL: 'https://calc.synchrorussia.ru/'
        baseURL: 'https://calc.outlight.pro/'
        // baseURL: 'http://localhost:8087/'
    });

    return instance;
}
