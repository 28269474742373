import React, {useEffect, useState} from 'react';
import {Select} from "antd";
import s from './styles.module.css'
import {useStore} from "../../../../hook/useStore";
import { useTranslation } from 'react-i18next'
import {getCookies} from "../../../../utils/getCookies";
import {observer} from "mobx-react-lite";

const AcroSolo = observer(({isEdit, onChange, data}) => {
    const {table, auth} = useStore()
    const { t } = useTranslation()
    const {name: token_name, value: token_value} = getCookies('token')
    const {name: hash_name, value: hash_value} = getCookies('hash')

    const [selectData, setSelectData] = useState([])

    useEffect(() => {
        const getAcroPairDir = () => {
            table.getAcroPairDir(auth.type === 'token' ? token_value : hash_value,)
        }
        getAcroPairDir()
    }, [])

    useEffect(() => {
        setSelectData(table?.acro_pair_dir?.map((el) => ({
            value: el.acro_pair_id,
            label: `${el.acro_pair_short} - ${el.acro_pair_value}`,
            short: el.acro_pair_short, ...el
        })))

    }, [table.loading_dir,table?.acro_pair_dir])

    useEffect(() => {
        if (isEdit) {
            const data_edit = table.edit_element.directory_params

            if (!data_edit || data_edit.length === 0) return
            const find_element = table?.acro_pair_dir.find((f) => +f?.acro_pair_id === +data_edit[0])

            if (!find_element) return

            onChange('hard', [{
                value: find_element?.acro_pair_short,
                id: find_element?.acro_pair_id,
                ks: find_element?.acro_pair_value
            }])
        }
    }, [table?.acro_pair_dir])

    return (
        <div className={s.acro}>
            <Select
                className={s.select}
                value={t("pairAcrobatic")}
                placeholder={t("pairAcrobatic")}
                defaultValue={t("pairAcrobatic")}
                style={{maxWidth: '670px', height: '48px'}}
                onChange={(a, b) => {
                    if ((data && data['hard']) ? data['hard']?.find((f) => f?.acro_pair_id === b?.acro_pair_id) : true) return
                    onChange('hard', [{value: b.short, id: b.acro_pair_id, ks: b.acro_pair_value}])
                }}
                options={selectData.map((el) => ({
                    ...el,
                    disabled: data['hard']?.find((f) => f?.id === el?.acro_pair_id),
                })).sort((a, b) => a.acro_pair_sort - b.acro_pair_sort)}
            />
        </div>
    );
});

export default AcroSolo;
