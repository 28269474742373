import api from "../../api/api";

class TableServices {
    async getTable(tokeOrHash) {
        return await api().get(`api/element/elements/${tokeOrHash}`)
    }

    async deleteTableItem(id, tokeOrHash) {
        return await api().get(`api/element/element_delete/${id}/${tokeOrHash}`)
    }

    async typeRowTable(tokenOrHash) {
        return await api().get(`api/element/directory_type/${localStorage.getItem('LOCALE')}/${tokenOrHash}`)
    }

    async addRowTable(type, tokenOrHash, payload) {
        if (type === 1) {
            return await api().post(`api/element/directory_transition_add/${tokenOrHash}`, {...payload})
        }
        if (type === 2) {
            return await api().post(`api/element/directory_hybrid_add/${tokenOrHash}`, {...payload})
        }
        if (type === 3) {
            return await api().post(`api/element/directory_tech_add/${tokenOrHash}`, {...payload})
        }
        if (type === 5) {
            return await api().post(`api/element/directory_acro_pair_add/${tokenOrHash}`, {...payload})
        }
        if (type === 4) {
            return await api().post(`api/element/directory_acro_add/${tokenOrHash}`, {...payload})
        }
        if (type === 6) {
            return await api().post(`api/element/directory_ChoHY_add/${tokenOrHash}`, {...payload});
        }
        if (type === 7) {
            return await api().post(`api/element/directory_SuCon_add/${tokenOrHash}`, {...payload});
        }
    }

    async editRowTable(type, tokenOrHash, payload) {
        // if (type === 1) {
        //     return await api().post(`api/element/directory_transition_add/${tokenOrHash}`, {...payload})
        // }
        if (type === 2) {
            return await api().post(`api/element/directory_hybrid_edit/${tokenOrHash}`, {...payload})
        }
        if (type === 3) {
            return await api().post(`api/element/directory_tech_edit/${tokenOrHash}`, {...payload})
        }
        if (type === 4) {
            return await api().post(`api/element/directory_acro_edit/${tokenOrHash}`, {...payload})
        }
        if (type === 5) {
            return await api().post(`api/element/directory_acro_pair_edit/${tokenOrHash}`, {...payload})
        }
    }

    async getLastTime(tokenOrHash) {
        return await api().get(`api/element/time_end/${tokenOrHash}`)
    }

    async getTechDir(tokenOrHash) {
        return await api().get(`api/element/directory_tech/${tokenOrHash}`)
    }

    async getAcroPairDir(tokenOrHash) {
        return await api().get(`api/element/directory_acro_pair/${tokenOrHash}`)
    }

    async getHybridBonusDir(tokenOrHash) {
        return await api().get(`api/element/directory_hybrid_bonus/${localStorage.getItem('LOCALE')}/${tokenOrHash}`)
    }

    async getHybridElementsDir(tokenOrHash) {
        return await api().get(`api/element/directory_hybrid/${localStorage.getItem('LOCALE')}/${tokenOrHash}`)
    }

    async getAcroTypeDir() {
        return await api().get(`api/element/directory_acro_type`)
    }

    async getAcroDir(id, tokenOrHash) {
        return await api().get(`api/element/directory_acro/${id}/${localStorage.getItem('LOCALE')}/${tokenOrHash}`)
    }

    async getEditItem(id, tokenOrHash) {
        return await api().get(`api/element/element_edit/${id}/${tokenOrHash}`)
    }

    async getText(ids, tokenOrHash) {
        return await api().post(`api/element/directory_acro_text/${tokenOrHash}`, {
            directory_acro_id: ids
        })
    }

    async getVersion(id) {
        return await api().get(`api/element/version/${id}`);
    }
}

export default new TableServices()
