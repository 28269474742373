import React, {useEffect, useState} from 'react';
import s from './styles.module.css'
import TimePiker from "./component/time_picker";
import Navigate from "./component/navigate";
import Action from "./component/action";
import AddedItem from "./component/added_item";
import Hybid from "./component/hybid";
import Tre from "./component/tre";
import Acro from "./component/acro";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {useStore} from "../../hook/useStore";
import {getCookies} from "../../utils/getCookies";
import AcroSolo from "./component/acro_solo";
import {addTimes} from "../../utils/plus_time";
import {toast} from "react-toastify";

const Add = observer(({isEdit}) => {
            const {name: token_name, value: token_value} = getCookies('token')
            const {name: hash_name, value: hash_value} = getCookies('hash')

            const {id} = useParams()

            const {table, auth} = useStore()

            const navigate_page = useNavigate()
            const location = useLocation()

            const [navigateAcro, setNavigateAcro] = useState({})
            const [navigateHybrid, setNavigateHybrid] = useState({})
            const [itemData, setItemData] = useState({})
            const [navigate, setNavigate] = useState(1)
            const [time, setTime] = useState({
                start_time: '00:00',
                end_time: '00:00',
                duration: '00:00'
            })
            const [ks_value, setKsValue] = useState(0)

            const handlerAddedItem = () => {
                const {name: token_name, value: token_value} = getCookies('token')
                const {name: hash_name, value: hash_value} = getCookies('hash')

                const dataTeh = {
                    1: {},
                    2: {
                        directory_hybrid_id: (itemData[navigate] && itemData[navigate]['hard']) ? itemData[navigate]['hard'].map((el) => el.id) : [null],
                        directory_hybrid_bonus_id: (itemData[navigate] && itemData[navigate]['bonus']) ? itemData[navigate]['bonus'].map((el) => el.id) : [null],
                        directory_hybrid_type: navigateHybrid.hybridName
                    },
                    3: {directory_tech_id: (itemData[navigate] && itemData[navigate]['hard']) ? itemData[navigate]['hard'][0]?.id : null},
                    5: {directory_acro_pair_id: (itemData[navigate] && itemData[navigate]['hard']) ? [itemData[navigate]['hard'][0]?.id] : null},
                    4: {directory_acro_id: (itemData[navigate] && itemData[navigate]['hard']) ? itemData[navigate]['hard'].map((el) => el.id) : null},
                    6: {},
                    7: {}
                }

                if (!isEdit) {
                    table.addRowTable(navigate, auth.type === 'token' ? token_value : hash_value,
                        {
                            time_start: `00:${time.start_time}`,
                            time_end: `00:${time.end_time}`,
                            ...dataTeh[navigate]
                        }, () => navigate_page(`/${location?.search}`)
                    )
                } else {
                    table.editRowTable(navigate, auth.type === 'token' ? token_value : hash_value, {
                        time_start: `00:${time.start_time}`,
                        time_end: `00:${time.end_time}`,
                        element_id: +id,
                        ...dataTeh[navigate]
                    }, () => navigate_page(`/${location?.search}`))
                }
            }

            useEffect(() => {
                let initial = {}
                for (let i = 0; i < table?.types?.length; i++) {
                    initial[table.types[i]?.directory_type_id] = {hard: [], bonus: []}
                }
                setItemData(initial)

            }, [navigate])

            useEffect(() => {
                const typeRowTable = () => {
                    const {name: token_name, value: token_value} = getCookies('token')
                    const {name: hash_name, value: hash_value} = getCookies('hash')

                    table.typeRowTable(auth.type === 'token' ? token_value : hash_value)
                    table.getLastTime(auth.type === 'token' ? token_value : hash_value)
                }
                typeRowTable()
                if (!isEdit) {
                    setTime({...time, start_time: table.last_time?.time, end_time: table.last_time?.time})
                } else {
                    if (table.edit_element) {
                        const start_time = table.edit_element.time_start?.split(':')
                        const end_time = table.edit_element.time_end?.split(':')

                        setTime({
                            ...time,
                            start_time: `${(start_time && start_time[1]) ? start_time[1] : '00'}:${(start_time && start_time[2]) ? start_time[2] : '00'}`,
                            end_time: `${(end_time && end_time[1]) ? end_time[1] : '00'}:${(end_time && end_time[2]) ? end_time[2] : '00'}`,
                            duration: addTimes(`${(start_time && start_time[1]) ? start_time[1] : '00'}:${(start_time && start_time[2]) ? start_time[2] : '00'}`, `${(end_time && end_time[1]) ? end_time[1] : '00'}:${(end_time && end_time[2]) ? end_time[2] : '00'}`)
                        })
                    }

                }

            }, [table.last_time?.time, table.edit_element, localStorage.getItem('LOCALE')])

            useEffect(() => {
                if (isEdit) {
                    setNavigate(table.edit_element.directory_type_id)
                }
            }, [table.loading_dir])

            useEffect(() => {
                if (isEdit) {
                    table.getEditItem(id, auth.type === 'token' ? token_value : hash_value)
                }

                if (isEdit) {
                    let part = location.state.part;
                    switch (part) {
                        case "HYBRID-S": {
                            setNavigateHybrid({hybridSize: 0, hybridName: 'S'});
                            break;
                        }

                        case "HYBRID-D": {
                            setNavigateHybrid({hybridSize: 100 / 3, hybridName: 'D'});
                            break;
                        }

                        case "HYBRID-T": {
                            setNavigateHybrid({hybridSize: 100 - (100 / 3), hybridName: 'T'});
                            break
                        }

                        default:
                            break;
                    }
                }
            }, [isEdit])

            useEffect(() => {
                let base;
                if (navigate === 2 || navigate === 4) {
                    base = 0.5;
                } else if (navigate === 5) {
                    base = 0.1;
                } else {
                    base = 0;
                }

                let newVar = ((itemData && itemData[navigate]) && (itemData[navigate]['hard'] || itemData[navigate]['bonus'])) ?
                    (itemData[navigate]['hard'] ? itemData[navigate]['hard'].reduce((acc, inc) => acc = acc + inc.ks, base) : base) +
                    (itemData[navigate]['bonus'] ? itemData[navigate]['bonus'].reduce((acc, inc) => acc = acc + inc.ks, 0) : 0)
                    : base;

                setKsValue(newVar)
            }, [itemData, table.loading_dir])

            useEffect(() => {
                if (navigate === 4 && itemData[navigate] && itemData[navigate]['hard']) {
                    table.getText(itemData[navigate]['hard'].map((el) => el.id), auth.type === 'token' ? token_value : hash_value)
                }
            }, [navigate, itemData])

            if (table.loading) {
                return null
            }

            function onChange(type, data) {

                if (navigate === 2) {
                    let iterEl = 1;
                    let iterGroup = 1
                    let last = data[data.length - 1];

                    let hard = itemData[2]?.hard;
                    for (let i = 0; i < hard.length; i++) {
                        if (hard[i].id === last?.id) {
                            iterEl++;
                        }
                        if (hard[i].group_id === last?.group_id) {
                            iterGroup++;
                        }
                    }

                    let message;
                    if (iterEl === 4) {
                        message = 'Превышен лимит по ' + last?.value;
                    } else if (iterGroup === 6) {
                        message = "Превышен лимит! Не более 5 для одной группы!"
                    }

                    if (message !== undefined) {
                        toast.error(message, {
                            position: "bottom-left",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                        })
                        return;
                    }
                    setItemData({
                        ...itemData,
                        [navigate]: {...itemData[navigate], [type]: data}
                    })
                } else {
                    setItemData({
                        ...itemData,
                        [navigate]: {...itemData[navigate], [type]: data}
                    })
                }
            }

            const swapElements = (array) => {
                if (array.some(value => value.directory_type_id === 6)) {
                    let result = [];
                    result[0] = array[0];
                    result[1] = array[3];
                    result[2] = array[1];
                    result[3] = array[2];
                    return result;
                } else if (array.some(value => value.directory_type_id === 7)) {
                    let result = [];
                    result[0] = array[0];
                    result[1] = array[array.length - 1];
                    result[2] = array[1];
                    result[array.length - 1] = array[array.length - 2];
                    if (array.length === 5) {
                        result[3] = array[2];
                    }
                    return result;
                }
                return array;
            };

            function setPart(nav) {
                switch (nav) {
                    case 1: {
                        return 'TRANS';
                    }

                    case 2: {
                        if (table.types.some(value => value.directory_type_id === 6)) {
                            return `HYBRID-${navigateHybrid?.hybridName}`;
                        } else {
                            return 'HYBRID';
                        }
                    }

                    case 3: {
                        return 'TRE';
                    }

                    case 4: {
                        return `ACRO-${navigateAcro?.acro_type_name}`;
                    }

                    case 5: {
                        return 'ACRO-PAIR';
                    }

                    case 6: {
                        return 'ChoHY';
                    }

                    default: {
                        return 'SuCon';
                    }
                }
            }

            return (
                <div className={s.content}>
                    <div>
                        <TimePiker isEdit={isEdit} setTime={setTime} time={time}/>

                        {isEdit ? null :
                            <Navigate types={swapElements(table.types)} setNavigate={setNavigate} navigate={navigate}/>}

                        <div className={s.navigate_content}
                             style={{display: (navigate === 1 && 'none' || navigate === 6 && 'none' || navigate === 7 && 'none')}}>
                            {navigate === 1 && <div/>}
                            {navigate === 2 && <Hybid isEdit={isEdit} data={itemData[navigate]}
                                                      checkEl={table.types.some(value => value.directory_type_id === 6)}
                                                      setNavigate={setNavigateHybrid}
                                                      navigateHybrid={navigateHybrid}
                                                      setItemData={setItemData}
                                                      itemData={itemData}
                                                      navigate={navigate}
                                                      isShowBonus={table.isShowBonus}
                                                      onChange={(type, data) => onChange(type, data)}/>
                            }
                            {navigate === 3 && <Tre isEdit={isEdit}
                                                    data={itemData[navigate]}
                                                    onChange={(type, data) => onChange(type, data)}/>}
                            {navigate === 5 && <AcroSolo isEdit={isEdit} data={itemData[navigate]}
                                                         onChange={(type, data) => onChange(type, data)}/>}
                            {navigate === 4 && <Acro data={itemData[navigate]}
                                                     setNavigate={setNavigateAcro}
                                                     navigate={navigateAcro}
                                                     isEdit={isEdit}
                                                     onChange={(type, data) => onChange(type, data)}/>}
                        </div>
                        <AddedItem
                            navigate={navigate}
                            delete_hard={() => {
                                const newArr = itemData[navigate]['hard'].slice(0, -1)

                                setItemData({
                                    ...itemData,
                                    [navigate]: {...itemData[navigate], ['hard']: [...newArr]}
                                })
                            }}
                            delete_bonus={() => {
                                const newArr = itemData[navigate]['bonus'].slice(0, -1)

                                setItemData({
                                    ...itemData,
                                    [navigate]: {...itemData[navigate], ['bonus']: [...newArr]}
                                })
                            }}
                            ks={ks_value.toFixed(3)}
                            el={navigate === 1 ? '' : (isEdit ? table?.edit_element?.el : table.last_time?.el)}
                            hard_data={itemData[navigate] ? itemData[navigate]['hard'] : {}}
                            bonus_data={itemData[navigate] ? itemData[navigate]['bonus'] : {}}
                            text={table.text}
                            part={setPart(navigate)}
                            time={{start_time: time.start_time, end_time: time.end_time}}
                        />
                    </div>
                    <Action hash={location?.search} isEdit={isEdit} handlerAddedItem={handlerAddedItem}/>
                </div>
            )
                ;
        }
    )
;

export default Add;
