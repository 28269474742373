import {makeAutoObservable} from "mobx";
import TableServices from "./srvices/table.services";
import {toast} from "react-toastify";

export class TableStore {
    loading = false
    table = []
    types = []
    isShowBonus = undefined
    last_time = null
    tech_dir = []
    acro_pair_dir = []
    hybrid_bonus_element_dir = {}
    loading_dir = false
    acro_type = []
    acro_dir = []
    edit_element = {}
    text = ''

    constructor() {
        makeAutoObservable(this)
    }

    setBonusElementHybrid(hybrid_bonus_element_dir) {
        this.hybrid_bonus_element_dir = hybrid_bonus_element_dir
    }

    setEditElement(edit_element) {
        this.edit_element = edit_element
    }

    setAcroDir(acro_dir) {
        this.acro_dir = acro_dir
    }

    setacroType(acro_type) {
        this.acro_type = acro_type
    }

    setLastTime(last_time) {
        this.last_time = last_time
    }

    setLoadingDir(loading_dir) {
        this.loading_dir = loading_dir
    }

    setLoading(loading) {
        this.loading = loading
    }

    setTable(table) {
        this.table = table
    }

    setAvilibleType(types) {
        this.types = types
    }

    setIsShowBonus(isShow) {
        this.isShowBonus = isShow;
    }

    setTechDir(tech_dir) {
        this.tech_dir = tech_dir
    }

    setAcroPairDir(acro_pair_dir) {
        this.acro_pair_dir = acro_pair_dir
    }

    setText(text) {
        this.text = text
    }

    async getText(ids, tokenOrHash) {
        try {
            const res = await TableServices.getText(ids, tokenOrHash)
            // debugger
            this.setText(res.data.text)
        } catch (e) {
            console.log(e)

        }
    }

    async getEditItem(id, tokenOrHash) {
        this.setLoadingDir(true)
        this.setLoading(true)
        try {
            const res = await TableServices.getEditItem(id, tokenOrHash)
            this.setEditElement(res.data)
            this.setLoading(false)
            this.setLoadingDir(false)
        } catch (e) {
            console.log(e)
            this.setLoading(false)
            this.setLoadingDir(false)
        }
    }

    async getAcroDir(id, tokenOrHash) {
        this.setLoadingDir(true)
        try {
            const res = await TableServices.getAcroDir(id, tokenOrHash)
            // debugger
            this.setAcroDir(res.data.group_acro)
        } catch (e) {
            console.log(e)
        } finally {
            this.setLoadingDir(false)
        }
    }

    async getAcroTypeDir() {
        this.setLoadingDir(true)
        try {
            const res = await TableServices.getAcroTypeDir()
            this.setacroType(res.data.acro_type)
        } catch (e) {
            console.log(e)
        } finally {
            this.setLoadingDir(false)
        }
    }

    async getBonusElementHybrid(tokenOrHash) {
        this.setLoadingDir(true)
        try {
            const res = await TableServices.getHybridBonusDir(tokenOrHash)
            const res2 = await TableServices.getHybridElementsDir(tokenOrHash)
            this.setBonusElementHybrid({
                elements: res2.data.directory_hybrid,
                bonus: res.data.directory_hybrid_bonus,
            })
        } catch (e) {
            console.log(e)
        } finally {
            this.setLoadingDir(false)
        }
    }

    async getAcroPairDir(tokenOrHash) {
        this.setLoadingDir(true)
        try {
            const res = await TableServices.getAcroPairDir(tokenOrHash)
            this.setAcroPairDir(res?.data?.directory_acro_pair)
        } catch (e) {
            console.log(e)
        } finally {
            this.setLoadingDir(false)
        }
    }

    async getTechDir(tokenOrHash) {
        this.setLoadingDir(true)
        try {
            const res = await TableServices.getTechDir(tokenOrHash)
            this.setTechDir(res.data.directory_tech)
        } catch (e) {
            console.log(e)
        } finally {
            this.setLoadingDir(false)
        }
    }

    async addRowTable(type, tokenOrHash, payload, navigate) {
        const locale = localStorage.getItem('LOCALE')
        try {
            if (type !== 1 && type !== 3 && type !== 5 && type !== 2 && type !== 4 && type !== 6 && type !== 7) {
                toast.error(locale === 'ru' ? 'Добавление не работает!' : "Adding doesn't work!", {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                })
                return
            }
            const res = await TableServices.addRowTable(type, tokenOrHash, payload)
            navigate()
            toast.success(locale === 'ru' ? 'Добавлено!' : "Added!", {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            })
        } catch (e) {
            console.log(e)
            toast.error(locale === 'ru' ? 'Ошибка!' : 'Error!', {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            })
        } finally {

        }
    }

    async editRowTable(type, tokenOrHash, payload, navigate) {
        const locale = localStorage.getItem('LOCALE')
        try {
            if (type !== 5 && type !== 3 && type !== 2 && type !== 4) {
                toast.error(locale === 'ru' ? 'Редактирование не работает!' : "Editing doesn't work!", {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                })
                return
            }
            const res = await TableServices.editRowTable(type, tokenOrHash, payload)
            navigate()
            toast.success(locale === 'ru' ? 'Сохранено!' : 'Saved!', {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            })
        } catch (e) {
            console.log(e)
            toast.error(locale === 'ru' ? 'Ошибка!' : 'Error!', {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            })
        } finally {

        }
    }

    async typeRowTable(tokenOrHash) {
        this.setLoading(true)
        try {
            const res = await TableServices.typeRowTable(tokenOrHash)
            this.setAvilibleType(res.data.directory_type)
            let find = res.data.directory_type.find((type) => type.is_show_bonus === true)
            this.setIsShowBonus(find !== undefined)
        } catch (e) {
            console.log(e)
        } finally {
            this.setLoading(false)
        }
    }

    async getLastTime(tokenOrHash) {
        try {
            const res = await TableServices.getLastTime(tokenOrHash)
            const convert = res?.data?.time_end?.split(':')?.slice(1, 3)?.join(':')

            this.setLastTime({time: convert, el: res?.data?.el || ''} || '00:00')
        } catch (e) {
            console.log(e)
        }
    }

    async getTable(tokenOrHash) {
        try {
            const res = await TableServices.getTable(tokenOrHash)
            this.setTable(res.data.elements)
        } catch (e) {
            console.log(e)
        }
    }

    async deleteTableItem(id, tokenOrHash) {
        const locale = localStorage.getItem('LOCALE')
        try {
            const res = await TableServices.deleteTableItem(id, tokenOrHash)
            if (res?.data?.status === 'ok') {
                this.setTable(this.table.filter((f) => f.element_id !== id))
                toast.success(locale === 'ru' ? 'Удалено!' : 'Deleted!', {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                })
            }

        } catch (e) {
            console.log(e)
        }
    }
}
