import React, {useEffect, useRef, useState} from 'react';
import s from './styles.module.css'
import {Button, Select, Tooltip} from "antd";
import Row from "../../component/row";
import {mock} from "./mock";
import {ReactComponent as Plus} from "../../assets/plus.svg";
import plus from '../../assets/plus.png'
import {ReactComponent as Edit} from "../../assets/edit.svg";
import { useTranslation } from 'react-i18next';
import {ReactComponent as Delete} from "../../assets/delete.svg";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import {Box, Dialog, DialogActions, DialogTitle, Modal, Skeleton, Typography} from "@mui/material";
import HashHeader from "./hash_header";
import {useStore} from "../../hook/useStore";
import {observer} from "mobx-react-lite";
import {getCookies} from "../../utils/getCookies";
import {toast} from "react-toastify";
import {Button as ButtonMui} from "@mui/material";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import navigate from "../add/component/navigate";
import TableServices from "../../store/srvices/table.services";
import { setLanguage } from '../../localization';


const DistiplinPage = observer(() => {
            const {auth, distiplines, table} = useStore()
            const location = useLocation()
            const navigate = useNavigate()
            const { t } = useTranslation()

            const {name: token_name, value: token_value} = getCookies('token')
            const {name: hash_name, value: hash_value} = getCookies('hash')
            const { Option } = Select;

            const [distiplinValue, setDistiplinValue] = useState(localStorage.getItem('discipline_id') || null)
            const [confirmClear, setConfirmClear] = useState(false)
            const [confirmHashClear, setConfirmHashClear] = useState(false)
            const [openModal, setOpenModal] = useState({
                open: false,
                index: null,
                id: null
            })
            const [file, setFile] = useState(null);
            const [uploadConfirmation, setUploadConfirmation] = useState(false);
            const hiddenFileInput = useRef(null);
            const [isDragOver, setIsDragOver] = useState(false);
            const [draggedFile, setDraggedFile] = useState(null);
            const [confirmDragUpload, setConfirmDragUpload] = useState(false);

            const handleClick = (event) => {
                hiddenFileInput.current.click();
            };

            const handleChange = (value) => {
                setDistiplinValue(value)
                localStorage.setItem('discipline_id', value)
                distiplines.setDistiplinesThemeMusic(auth.value, {'discipline_id': value})
            };

            const handlerClear = () => {
                distiplines.clearCard(auth.value, () => table.getTable(auth.type === 'token' ? token_value : hash_value))
                setConfirmClear(false)
            }

            const handlerHashClear = async () => {
                await distiplines.saveCard(auth.value)
                await auth.setHash(auth.value)

                setConfirmHashClear(false)
            }
            const handlerDeleteItem = () => {
                table.deleteTableItem(openModal.id, auth.type === 'token' ? token_value : hash_value)
                setOpenModal({...openModal, open: false})
            }

            const downloadFile = () => {
                distiplines.downloadFile(auth.type === 'token' ? token_value : hash_value)
                    .then((response) => {
                        const url = window.URL.createObjectURL(new Blob([response.data]))
                        const link = document.createElement("a")
                        link.href = url
                        link.setAttribute(
                            "download",
                            `file.synchro`
                        )
                        document.body.appendChild(link);
                        link.click();
                        link.parentNode.removeChild(link);
                    })
            }

            const handleFileChange = (e) => {
              const selectedFile = e.target.files[0];
              if (selectedFile) {
                  const allowedExtensions = ['synchro'];
                  const fileExtension = selectedFile.name.split('.').pop().toLowerCase();
                  if (allowedExtensions.includes(fileExtension)) {
                    setFile(selectedFile);
                    setUploadConfirmation(true);
                  } else {
                    hiddenFileInput.current.value = '';
                    toast.error(t("fileExtensionError"), {
                      position: "bottom-left",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "colored",
                    })
                  }
              }
            };

            const handleFileUpload = async () => {
                const formData = new FormData();
                formData.append('file', file);
                await distiplines.uploadFile(formData, auth.type === 'token' ? token_value : hash_value).then(() => {
                    table.getTable(auth.type === 'token' ? token_value : hash_value)
                    setFile(null);
                    setUploadConfirmation(false);
                    hiddenFileInput.current.value = '';
                });
            };

            const handleDraggedFile = (file) => {
                if (file) {
                    const allowedExtensions = ['synchro'];
                    const fileExtension = file.name.split('.').pop().toLowerCase();
                    if (allowedExtensions.includes(fileExtension)) {
                        setDraggedFile(file);
                        setConfirmDragUpload(true);
                    } else {
                        toast.error(t("fileExtensionError"), {
                            position: "bottom-left",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                        })
                    }
                }
            };

            const handleFileUploadConfirmation = async () => {
                setConfirmDragUpload(false);
                await handleFileDragUpload();
            };

            const handleFileDragUpload = async () => {
                if (draggedFile) {
                    const formData = new FormData();
                    formData.append('file', draggedFile);
                    await distiplines.uploadFile(formData, auth.type === 'token' ? token_value : hash_value);
                    await table.getTable(auth.type === 'token' ? token_value : hash_value);
                    setDraggedFile(null);
                }
            };
            
            const handleLang = (value) => {
              setLanguage(value)
            }

            useEffect(() => {
                const getDistiplinesCardInfo = () => {
                    table.getTable(auth.type === 'token' ? token_value : hash_value)
                    distiplines.getDistiplinesCardInfo(auth.type === 'token' ? token_value : hash_value)
                    distiplines.getDistiplines()
                }
                getDistiplinesCardInfo()
            }, [localStorage.getItem('LOCALE')])

            return (
                <div className={s.content}>
                    <div className={s.header_btn}>
                      <div className={s.file_actions}>
                        <Tooltip title={t("export")} placement="top">
                          <ButtonMui
                            onClick={downloadFile}
                            disabled={table?.table?.length === 0}
                            sx={{ backgroundColor: '#fff', height: '45px', width: '58px' }}
                            variant="outlined"
                          >
                            <FileDownloadIcon />
                          </ButtonMui>
                        </Tooltip>
                        <input
                          type="file"
                          id="fileInput"
                          onChange={handleFileChange}
                          ref={hiddenFileInput}
                          style={{ display: "none" }}
                        />
                        <Tooltip title={t("import")} placement="top">
                          <ButtonMui
                            sx={{ backgroundColor: '#fff', height: '45px', width: '58px' }}
                            variant="outlined"
                            component="span"
                            onClick={handleClick}
                          >
                            <FileUploadIcon />
                          </ButtonMui>
                        </Tooltip>
                    </div>
                    <div className={s.changeLanguageWrapper}>
                      <Button className={s.btn} type="primary" onClick={() => {
                            if (auth.type === 'token') {
                                setConfirmClear(true)
                            } else {
                                setConfirmHashClear(true)
                            }
                        }}>
                            {auth.type !== 'token' ? t('saveTheCoachCard') : t('clearTheCoachCard')}
                        </Button>
                        <Select value={localStorage.getItem("LOCALE")} onChange={handleLang} className={s.selectLang}>
                          <Option value="ru" style={{ fontSize: '18px' }}>RU</Option>
                          <Option value="en" style={{ fontSize: '18px' }}>EN</Option>
                      </Select>
                      </div>
                    </div>
                    {auth.type === 'hash' ? <HashHeader/> : <div className={s.selected_box}>
                        <h3 className={s.selected_box_title}>{t('event')}</h3>

                        <Select
                            className={s.select}
                            listHeight={450}
                            aria-rowcount={2}
                            aria-colcount={2}
                            maxLength={2}
                            aria-valuemax={2}
                            maxTagCount={2}
                            maxTagPlaceholder={2}
                            maxTagTextLength={2}

                            // defaultValue="lucy"
                            disabled={localStorage.getItem('discipline_id') && table?.table?.length !== 0}
                            placeholder={t("choiseEvent")}

                            value={distiplines?.distiplines?.find(f => f.discipline_id == localStorage.getItem('discipline_id'))?.discipline_name}
                            style={{maxWidth: 578, width: '100%', height: '60px'}}
                            onChange={handleChange}
                            options={distiplines.distiplines?.map((el) => ({
                                value: el?.discipline_id,
                                label: el?.discipline_name
                            }))}
                        />
                    </div>}

                    <div className={`${s.table} ${isDragOver ? s.dragOver : ''}`}
                         onDragOver={(e) => {
                             e.preventDefault();
                             setIsDragOver(true);
                         }}
                         onDragLeave={() => setIsDragOver(false)}
                         onDrop={(e) => {
                             e.preventDefault();
                             setIsDragOver(false);
                             handleDraggedFile(e.dataTransfer.files[0]);
                         }}>
                        <div className={s.table_header}>
                            <Row style={{padding: '24px 16px'}} width={170} isHeader>{t("time")}</Row>
                            <Row style={{padding: '24px 16px'}} width={130} isHeader>{t("part")}</Row>
                            <Row style={{padding: '24px 16px'}} width={80} isHeader>{t("el")}</Row>
                            <Row style={{padding: '24px 16px'}} width={368} isHeader>{t("declaredDifficulty")}</Row>
                            <Row style={{padding: '24px 16px'}} width={270} isHeader>{t("bonus")}</Row>
                            <Row style={{padding: '24px 16px'}} width={120} isHeader>{t("dd")}</Row>
                            <Row style={{padding: '24px 0'}} width={96} position={'right'} isHeader>
                                <Tooltip title={t("addElement")}>
                                    <NavLink onClick={(e) => {
                                        if ((distiplinValue === null && !localStorage.getItem('discipline_id')) && auth.type === 'token') {
                                            e.preventDefault()
                                            toast.error(t("choiseEvent"), {
                                                position: "bottom-left",
                                                autoClose: 5000,
                                                hideProgressBar: false,
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: true,
                                                progress: undefined,
                                                theme: "colored",
                                            })
                                        }

                                    }} to={`/add${location?.search}`}>
                                        <div className={s.plus_icon}>
                                            <img src={plus} alt=""/>
                                            {/*<Plus/>*/}
                                        </div>
                                    </NavLink>
                                </Tooltip>
                            </Row>
                        </div>

                        <div className={s.table_content}>
                            {table?.table?.map((item, i) => {
                                const timestart = item.time_from?.split(':')
                                const timeend = item.time_to?.split(':')
                                return <div className={s.item} key={i}>
                                    <Row width={170}
                                         style={{padding: '0 16px'}}>{`${timestart[1]}:${timestart[2]}`} - {`${timeend[1]}:${timeend[2]}`}
                                    </Row>
                                    <Row width={130} style={{padding: '0 16px'}}>{item.part}</Row>
                                    <Row width={80} style={{padding: '0 16px'}}>{item.el}</Row>
                                    <Row width={368} style={{padding: '0 16px'}}>{item.element}</Row>
                                    <Row width={270} style={{padding: '0 16px'}} position={'left'}>{item.bonus}</Row>
                                    <Row width={120} style={{padding: '0 16px'}}>{item.dd?.toFixed(3)}</Row>
                                    <Row width={96} style={{padding: '0 0px'}}>
                                        <div className={s.content_icons}>
                                            <Tooltip title={'Редактировать'}>
                                                <div className={s.edit_icon}
                                                     onClick={() => {
                                                         if (item.part === 'TRANS' || item.part === 'ChoHY' || item.part === 'SuCon') return;
                                                         navigate(`/edit/${item.element_id}${location?.search}`, {state: {part: item.part}});
                                                     }}>
                                                    {(item.part !== 'TRANS' && item.part !== 'ChoHY' && item.part !== 'SuCon') && <Edit/>}
                                                </div>
                                            </Tooltip>
                                            <Tooltip title={t("delete")}>
                                                <div className={s.delete_icon}
                                                     onClick={() => setOpenModal({
                                                         open: true,
                                                         index: i + 1,
                                                         id: item?.element_id
                                                     })}><Delete/></div>
                                            </Tooltip>
                                        </div>
                                    </Row>
                                </div>
                            })}
                        </div>

                    </div>

                    <Dialog
                        open={openModal.open}
                        onClose={() => setOpenModal({...openModal, open: false})}
                    >
                        <DialogTitle id="alert-dialog-title">
                            {`${t("removeItemFromCoachCard")} №${openModal.index} ?`}
                        </DialogTitle>

                        <DialogActions>
                            <Button onClick={() => setOpenModal({...openModal, open: false})}>{t("cancel")}</Button>
                            <Button onClick={handlerDeleteItem} type={'primary'}>
                              {t("delete")}
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        open={confirmClear}
                        onClose={() => setConfirmClear(false)}
                    >
                        <DialogTitle id="alert-dialog-title">
                            {t("areYouSureYouWantToClearTheCoachCard")}
                        </DialogTitle>

                        <DialogActions>
                            <Button onClick={() => setConfirmClear(false)}>{t("cancel")}</Button>
                            <Button onClick={handlerClear} type={'primary'}>
                              {t("clear")}
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        open={confirmHashClear}
                        onClose={() => setConfirmHashClear(false)}
                    >
                        <DialogTitle id="alert-dialog-title">
                          {t("areYouSureYouWantToClearTheCoachCard")}
                        </DialogTitle>

                        <DialogActions>
                            <Button onClick={() => setConfirmHashClear(false)}>{t("cancel")}</Button>
                            <Button onClick={handlerHashClear} type={'primary'}>
                              {t("save")}
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={uploadConfirmation} onClose={() => setUploadConfirmation(false)}>
                      <DialogTitle id="alert-dialog-title">{t("areYouSureYouWantToSaveTheCoachCard")}</DialogTitle>
                      <DialogActions>
                        <Button onClick={() => setUploadConfirmation(false)}>{t("cancel")}</Button>
                        <Button onClick={handleFileUpload} type="primary">
                          {t("import")}
                        </Button>
                      </DialogActions>
                    </Dialog>
                    <Dialog
                        open={confirmDragUpload}
                        onClose={() => setConfirmDragUpload(false)}
                    >
                      <DialogTitle id="alert-dialog-title">
                        {t("areYouSureYouWantToSaveTheCoachCard")}
                      </DialogTitle>
                      <DialogActions>
                        <Button onClick={() => setConfirmDragUpload(false)}>{t("cancel")}</Button>
                        <Button onClick={handleFileUploadConfirmation} type="primary">
                          {t("import")}
                        </Button>
                      </DialogActions>
                    </Dialog>
                </div>
            );
        }
    )
;

export default DistiplinPage;
